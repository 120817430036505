export enum FormErrorCodeEnum {
  Required = 'required',
  Email = 'email',
  Phone = 'phone',
  Name = 'realName',
  AtLeastOneChecked = 'atLeastOneChecked',
  EmailTaken = 'emailTaken',
  CodeInvalid = 'codeInvalid',
  PersonChosen = 'personChosen',
}

export const FORM_ERROR_MESSAGE = new Map([
  [FormErrorCodeEnum.Required, 'This field is required.'],
  [FormErrorCodeEnum.Phone, 'Invalid phone number'],
  [FormErrorCodeEnum.Email, 'Invalid email address'],
  [FormErrorCodeEnum.Name, 'Invalid name'],
  [FormErrorCodeEnum.AtLeastOneChecked, 'At least one checked'],
  [FormErrorCodeEnum.EmailTaken, 'Email has already been taken'],
  [FormErrorCodeEnum.CodeInvalid, 'Verification code is incorrect.'],
  [FormErrorCodeEnum.PersonChosen, 'This person has been chosen'],
]);
