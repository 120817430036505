import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'stream-captcha-v2-child',
  template: `<re-captcha
    #captchaRef="reCaptcha"
    size="invisible"
    (resolved)="onResolved($event)"
    (error)="onErrored($event)"
  ></re-captcha>`
})
export class ReCaptchaV2ChildComponent implements AfterViewInit {
  @ViewChild('captchaRef')
  private captchaRef!: RecaptchaComponent;

  @Output()
  resolved = new EventEmitter<string>();

  @Output()
  errorEvent = new EventEmitter<unknown>();

  ngAfterViewInit(): void {
    this.execute();
  }

  onResolved(token: string | null) {
    this.resolved.emit(token as string);
  }

  onErrored($event: unknown) {
    this.errorEvent.emit($event);
  }

  execute() {
    this.captchaRef.execute();
  }
}

@Component({
  selector: 'stream-captcha-v2',
  template: `<div>
    <stream-captcha-v2-child
      #reCaptchaV2Child
      *ngIf="showV2ReCaptcha"
      (resolved)="onResolved($event)"
      (errorEvent)="onErrored($event)"
    >
    </stream-captcha-v2-child>
  </div>`
})
export class ReCaptchaV2Component {
  @ViewChild('reCaptchaV2Child')
  private reCaptchaV2Child!: ReCaptchaV2ChildComponent;

  @Output()
  resolved = new EventEmitter<string>();

  @Output()
  errorEvent = new EventEmitter<unknown>();

  showV2ReCaptcha = false;

  onResolved(token: string) {
    this.resolved.emit(token);
    this.showV2ReCaptcha = false;
  }

  onErrored($event: unknown) {
    this.errorEvent.emit($event);
  }

  public execute() {
    if (this.showV2ReCaptcha) {
      this.reCaptchaV2Child.execute();
    } else {
      this.showV2ReCaptcha = true;
    }
  }
}
