import { TaskMandatoryDocument } from './tasks-interface';

export enum InboxMessageType {
  AppointmentCanStartOrders = 'APPOINTMENT_CAN_START_ORDERS', // 预约可以开始的订单
  SignedOrders = 'SIGNED_ORDERS', // 签署中的订单
  UnconfirmedCapitalCall = 'UNCONFIRMED_CAPITAL_CALL', // 未确认的Capital call
  NAVUpdate = 'NAV_UPDATE', // nav 净值文件更新
  TargetFundConfirmation = 'TARGET_FUND_CONFIRMATION', // 目标基金确认书
  ReceiptOfFunds = 'RECEIPT_OF_FUNDS', // 收款
  InvestedSuccessfully = 'INVESTED_SUCCESSFULLY', //投资成功
  AssistInvestEntity = 'ASSIST_INVEST_ENTITY', //review
  AssistInvestIndividual = 'ASSIST_INVEST_INDIVIDUAL', //review
  AssistInvestJoint = 'ASSIST_INVEST_JOINT', //review
  KYCSuccessNotify = 'KYC_SUCCESS_NOTIFY', //kyc状态改变
  SubscriptionApproved = 'SUBSCRIPTION_APPROVED', //rask rating审核通过
  SubscriptionRejected = 'SUBSCRIPTION_REJECTED',
  ReserveSuccessfully = 'RESERVE_SUCCESSFULLY', //预约成功
  AddressMaterialTask = 'ADDRESS_MATERIAL_TASK',
  BankMaterialTask = 'BANK_MATERIAL_TASK',
  IdentityMaterialTask = 'IDENTITY_MATERIAL_TASK',
  SubscriptionRedeem = 'SUBSCRIPTION_REDEEM',
  ExpressInterest = 'INVESTOR_EXPRESS_INTEREST',
  LPInvestorCallReceived = 'LP_INVESTOR_CALL_RECEIVED',
  CapitalCall = 'CAPITAL_CALL',
  OpenCapitalCall = 'OPEN_CAPITAL_CALL',
  MandatoryDocumentsTask = 'MANDATORY_DOCUMENTS_TASK',
  AdditionalDocumentsTask = 'ADDITIONAL_DOCUMENTS_TASK',
  ModifySubscriptionTask = 'MODIFY_SUBSCRIPTION_TASK',
  SubscriptionApprovedNotice = 'SUBSCRIPTION_APPROVED_NOTICE',
  LPProductSubscriptionRedemption = 'LP_PRODUCT_SUBSCRIPTION_REDEMPTION',
  RedemptionRequest = 'REDEMPTION_REQUEST',
  CloseCapitalCallWithIRA = 'CLOSED_CAPITAL_CALL_WITH_IRA',
  OpenCapitalCallWithIRA = 'OPEN_CAPITAL_CALL_WITH_IRA',
  FECCloseCapitalCallWithIRAResult = 'FEC_CLOSE_CAPITAL_CALL_WITH_IRA_RESULT', // Front end custom
  FECOpenCapitalCallWithIRAResult = 'FEC_OPEN_CAPITAL_CALL_WITH_IRA_RESULT' // Front end custom
}

export enum SearchInboxMessageType {
  Task = 'TASK_MESSAGE',
  Notification = 'COMMON_MESSAGE'
}

export enum TaskStatus {
  Todo = 'IN_PROGRESS',
  Closed = 'CLOSED',
  Done = 'DONE',
  All = 'ALL'
}

export enum InboxStatus {
  Read = 'READ', // 已读
  Unread = 'UNREAD' // 未读
}
export enum InboxAction {
  View = 'View investment', //查看订单详情
  Invest = 'invest', // 投资
  ViewOpportunities = 'View opportunities',
  Upload = 'Upload',
  Instruct = 'Instruct',
  AmendYourInvestment = 'Amend your investment'
}

export interface InboxMessage {
  id: string;
  inBoxMessageType: InboxMessageType;
  messageContent: string;
  params: string;
  recId: string;
  sendDate: string;
  sendId: string;
  status: InboxStatus;
  title: string;
  subtitle: string;
  taskStatusEnum: TaskStatus;
  taskResult: string;
}

export interface InboxMessageContent {
  FUND_NAME: string;
  GP_NAME: string;
  RECIPIENT_FIRST_NAME: string;
  SALES_SUPPORT_EMAIL: string;
  MANDATORY_DOCUMENTS: TaskMandatoryDocument[];
  systemDocumentNameJson: string;
  mandatoryDocuments: string;
  [key: string]: any;
}

export interface InboxMessageParams {
  productId: string;
  productSubscriptionId: string;
  taskId: string;
  profileId?: string;
  investorCallId?: string;
}

export interface InboxDetailMessage extends Omit<InboxMessage, 'messageContent' | 'params'> {
  messageContent: InboxMessageContent;
  params: InboxMessageParams;
}

export enum TaskUploadFileErrorEnum {
  TASK_NOT_EXISTS = 'TASK_NOT_EXISTS',
  TASK_STATUS_ILLEGAL = 'TASK_STATUS_ILLEGAL',
  ILLEGAL_TASK = 'ILLEGAL_TASK',
  TASK_PARSE_ERROR = 'TASK_PARSE_ERROR',
  SUBSCRIPTION_NOT_EXISTS = 'SUBSCRIPTION_NOT_EXISTS',
  SUBSCRIPTION_PROFILE_EXISTS = 'SUBSCRIPTION_PROFILE_EXISTS',
  SUBSCRIPTION_DATA_ROOM_ERROR = 'SUBSCRIPTION_DATA_ROOM_ERROR',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  NO_OPERATION_PERMISSION = 'NO_OPERATION_PERMISSION',
  TASK_CLOSED = 'TASK_CLOSED'
}

export const TaskUploadFileError: {
  [key in TaskUploadFileErrorEnum]: string;
} = {
  [TaskUploadFileErrorEnum.TASK_NOT_EXISTS]: 'Task does not exist!',
  [TaskUploadFileErrorEnum.TASK_STATUS_ILLEGAL]: 'This task has been completed by someone else.',
  [TaskUploadFileErrorEnum.ILLEGAL_TASK]: 'Task is illegal!',
  [TaskUploadFileErrorEnum.TASK_PARSE_ERROR]: 'Task parsing error!',
  [TaskUploadFileErrorEnum.SUBSCRIPTION_NOT_EXISTS]: 'Subscription does not exist!',
  [TaskUploadFileErrorEnum.SUBSCRIPTION_PROFILE_EXISTS]: 'Profile does not exist!',
  [TaskUploadFileErrorEnum.SUBSCRIPTION_DATA_ROOM_ERROR]: 'Subscription data room error!',
  [TaskUploadFileErrorEnum.FAILED]: 'Failed!',
  [TaskUploadFileErrorEnum.SUCCESS]: 'Success!',
  [TaskUploadFileErrorEnum.NO_OPERATION_PERMISSION]:
    'You have no permission to handle this task with your account type.',
  [TaskUploadFileErrorEnum.TASK_CLOSED]: 'This task has been completed by someone else.'
};

export enum TaskTransferAmountResEnum {
  SUCCESS = 'SUCCESS',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  PARAMS_ERROR = 'PARAMS_ERROR'
}

export enum TaskTransferAmountErrEnum {
  AWAITING_FCM_ACCOUNT_OPEN = 'Awaiting FCM Account Open',
  AWAITING_FUNDS = 'Awaiting Funds',
  AWAITING_PRE_CUSTODY = 'Awaiting Pre-Custody', //
  AWAITING_RESOLUTION = 'Awaiting Resolution',
  CANCELLED = 'Cancelled',
  COMPLETE = 'Complete',
  ON_HOLD = 'On Hold',
  PROCESSED = 'Processed',
  RECEIVED = 'Received',
  REVIEWING = 'Reviewing',
  SCHEDULED = 'Scheduled'
}

export const TaskTransferAmountError: {
  [key in TaskTransferAmountErrEnum]: string;
} = {
  [TaskTransferAmountErrEnum.AWAITING_FCM_ACCOUNT_OPEN]:
    'Inspira Financial is waiting for the account to open at your FCM or Broker/Dealer.',
  [TaskTransferAmountErrEnum.AWAITING_FUNDS]:
    'Inspira Financial is waiting for your account to be funded.', // To be handled separately
  [TaskTransferAmountErrEnum.AWAITING_PRE_CUSTODY]:
    'Inspira Financial needs to complete the Pre-Custody Process before processing your purchase.',
  [TaskTransferAmountErrEnum.AWAITING_RESOLUTION]:
    'Please contact Inspira Financial to obtain more information.',
  [TaskTransferAmountErrEnum.CANCELLED]: 'Your Investment Purchase has been cancelled.',
  [TaskTransferAmountErrEnum.COMPLETE]:
    'Inspira Financial sent the funds to the Investment Sponsor and received confirmation from the Investment Sponsor that the purchase has been completed',
  [TaskTransferAmountErrEnum.ON_HOLD]:
    'Inspira Financial is currently waiting for information from your Investment Sponsor.',
  [TaskTransferAmountErrEnum.PROCESSED]:
    'Inspira Financial sent the funds to the Investment Sponsor. Inspira Financial is currently waiting for the Sponsor to confirm the purchase has been completed.',
  [TaskTransferAmountErrEnum.RECEIVED]: 'Inspira Financial has received your investment direction.',
  [TaskTransferAmountErrEnum.REVIEWING]:
    'Inspira Financial is reviewing your investment direction.',
  [TaskTransferAmountErrEnum.SCHEDULED]:
    'Inspira Financial will send the funds to the Investment Sponsor on purchase-date.'
};
