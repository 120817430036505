import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

import { getColor } from '../../../utils';
import { AuthService } from '../../core/service/auth.service';

@Directive({
  selector: '[streamRandomColor]',
})
export class RandomColorDirective implements OnChanges {
  @Input()
  streamRandomColor = '';

  constructor(private el: ElementRef, private authService: AuthService) {}

  ngOnChanges() {
    this.el.nativeElement.style.backgroundColor = getColor(
      this.streamRandomColor.charCodeAt(0) % 4 || 0
    );
  }
}
