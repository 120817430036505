import { Component, Input } from "@angular/core";

@Component({
  selector: "stream-no-data",
  templateUrl: "./no-data.component.html",
  styleUrls: ["./no-data.component.scss"],
})
export class NoDataComponent {
  @Input()
  type:
    | "wave"
    | "magnifier"
    | "exclamatory"
    | "country"
    | "success"
    | "template" = "wave";
}
