import { Address } from '.';
import { CommonDataStatusEnum } from '../status.enum';

export enum KycStatusEnum {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  SalesSupport = 'SALES_SUPPORT',
  Expired = 'EXPIRED',
  Unverified = 'UNVERIFIED',
}
export const PRINCIPAL_KYC_STATUS = new Map([
  [KycStatusEnum.Success, 'Pass'],
  [KycStatusEnum.Failed, 'Failed'],
  [KycStatusEnum.SalesSupport, 'Sales support'],
  [KycStatusEnum.Expired, 'Expired'],
  [KycStatusEnum.Unverified, 'Unauthorized'],
]);

export enum PrincipalStatusEnum {
  Normal = 'normal',
  NoValidation = 'noValidation',
}

export const PRINCIPAL_STATUS_LABEL = new Map([
  [PrincipalStatusEnum.Normal, 'Active'],
  [PrincipalStatusEnum.NoValidation, 'Inactive'],
]);

export enum PrincipalStatusInviteEnum {
  NOT_INVITE = 'NOT_INVITE',
  INVITED = 'INVITED',
  REGISTERED = 'REGISTERED',
  LOCKED = 'LOCKED',
}

export const PRINCIPAL_STATUS_INVITE_LABEL = new Map([
  [PrincipalStatusInviteEnum.NOT_INVITE, 'Not invited'],
  [PrincipalStatusInviteEnum.INVITED, 'Invited'],
  [PrincipalStatusInviteEnum.REGISTERED, 'Registered'],
  [PrincipalStatusInviteEnum.LOCKED, 'Locked'],
]);

export enum PrincipalTypeEnum {
  Individual = 'individual',
  // Enterprise = 'enterprise',
  Institutional = 'institutional',
  NotVerified = 'notVerified',
}

export enum PrincipalRoleEnum {
  member = 'MEMBER',
  admin = 'ADMIN',
  owner = 'OWNER',
}
export const PRINCIPAL_ROLE_LABEL = new Map([
  [PrincipalRoleEnum.member, 'Member'],
  [PrincipalRoleEnum.admin, 'Admin'],
  [PrincipalRoleEnum.owner, 'Owner'],
]);

export enum PrincipalIdentityEnum {
  QP = 'QP',
  AI = 'AI',
  HNWI = 'HNWI',
  SI = 'SI',
  EPI = 'EPI',
  PSPI = 'PSPI',
  EC = 'EC',
  PI = 'PI',
  IPI = 'IPI',
  CPI = 'CPI',
  INPI = 'INPI',
  IAI = 'IAI',
  CAI = 'CAI',
  II = 'II',
  WIPVT = 'WIPVT',
  WIWT = 'WIWT',
  WISBT = 'WISBT',
  WIPIT = 'WIPIT',
  QUALIFIED_INVESTOR = 'QUALIFIED_INVESTOR',
  RETAIL_INVESTOR = 'RETAIL_INVESTOR',
  FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
  BROKER_DEALER = 'BROKER_DEALER',
  UNCLASSIFIED = 'UNCLASSIFIED',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export const PRINCIPAL_IDENTITY_LABEL = new Map([
  [PrincipalIdentityEnum.QP, 'Qualified Purchaser'],
  [PrincipalIdentityEnum.AI, 'Accredited Investor'],
  [PrincipalIdentityEnum.HNWI, 'High Net Worth Investor'],
  [PrincipalIdentityEnum.SI, 'Sophisticated Investor'],
  [PrincipalIdentityEnum.EPI, 'Elective Professional Investor'],
  [PrincipalIdentityEnum.PSPI, 'Per se Professional Investor'],
  [PrincipalIdentityEnum.EC, 'Eligible Counterparty'],
  [PrincipalIdentityEnum.PI, 'Professional Investor'],
  [PrincipalIdentityEnum.IPI, 'Individual Professional Investor'],
  [PrincipalIdentityEnum.CPI, 'Corporate Professional Investor'],
  [PrincipalIdentityEnum.INPI, 'Institutional Professional Investor'],
  [PrincipalIdentityEnum.IAI, 'Individual Accredited Investor'],
  [PrincipalIdentityEnum.CAI, 'Corporate Accredited Investor'],
  [PrincipalIdentityEnum.II, 'Institutional Investor'],
  [PrincipalIdentityEnum.WIPVT, 'Wholesale Investor - Price or Value Test'],
  [PrincipalIdentityEnum.WIWT, 'Wholesale Investor - Wealth Test'],
  [PrincipalIdentityEnum.WISBT, 'Wholesale Investor - Size of Business Test'],
  [PrincipalIdentityEnum.WIPIT, 'Wholesale Investor - Professional Investor Test'],
  [PrincipalIdentityEnum.QUALIFIED_INVESTOR, 'Qualified Investor'],
  [PrincipalIdentityEnum.RETAIL_INVESTOR, 'Retail Investor'],
  [PrincipalIdentityEnum.FINANCIAL_ADVISOR, 'Financial Advisor'],
  [PrincipalIdentityEnum.BROKER_DEALER, 'Broker-dealer'],
  [PrincipalIdentityEnum.UNCLASSIFIED, 'Unclassified'],
  [PrincipalIdentityEnum.TEAM_MEMBER, 'Team member'],
]);

export interface Principal {
  accountRole: string;
  address: Address;
  avatar: string;
  companyName: string;
  countryAlpha2Code: string;
  dataCreateTime: Date;
  dataIsDeleted: boolean;
  dataStatus: CommonDataStatusEnum;
  dataUpdateTime: Date;
  dateOfBirth: Date;
  email: string;
  firstName: string;
  id: string;
  kycStatus: KycStatusEnum;
  lastName: string;
  nameCardCloud: string;
  nameCardUrl: string;
  placeOfBirth: Address;
  status: PrincipalStatusEnum;
  telephone: string;
  telephoneCode: string;
  telephoneCurrencyCode: string;
  tenantId: string;
  principalType: PrincipalTypeEnum;
  principalToken: string;
  principalIdentity: PrincipalIdentityEnum;
  principalIdentityEnumForTag: PrincipalIdentityEnum;
  region: string;
  kycSignLogs: [KycDetail];
  haveKycSignLogs: boolean;
  type?: PrincipalTypeEnum;
  wmCandidate: boolean;
  screeningStatus?: string;
}

export interface KycDetail {
  comments: string;
  id: string;
  dataIsDeleted: boolean;
  dataCreateTime: Date;
  dataStatus: string;
  oldStatus: KycStatusEnum;
  status: KycStatusEnum;
  times: string;
  principalId: string;
  signType: string;
  tenantId: string;
  documents: {
    dataRoomDocumentId: string;
    documentToken: string;
    name: string;
    url: string;
  }[];
}

export interface PrincipalMember {
  areaCode: string;
  countryAlpha2Code: string;
  createTime: string;
  dataType: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
  phoneCurrencyCode: string;
  position: string;
  role: string;
  status: PrincipalStatusEnum;
  tenantId: string;
}
