export enum Stage {
  PROSPECT = 'PROSPECT', // 展望
  MEETING = 'MEETING', // 电话阶段
  DATA_ROOM = 'DATA_ROOM', // 文件阶段
  SOFT_COMMITMENT = 'SOFT_COMMITMENT', // 委托阶段
  COMMITTED = 'COMMITTED', // 提交
  DECLINE = 'DECLINE', // 拒绝
}

export interface Opportunity {
  productName: string;
  id: string;
  productId: string;
  saleId: string;
  stage: Stage;
  tenantId: string;
  investPrincipalId: string;
  investAmount: string;
  dataStatus: string;
  comments: string;
  closingDate: string;
  dataIsDeleted: string;
  targetSize: number;
  principalId?: string;
  currency?: string;
  sales?: string;
  email: string;
  investmentId: string;
  investmentName: string;
  phoneNumber: string;
  salesName: string;
  times: string;
  lastViewProductTime: string;
  opportunitySource?: PipelineSourceEnum;
  isWmOpportunity?: boolean;
  hideInvestorDetails?: boolean;
  tenantLogoUrl?: string;
  tenantName?: string;
  productTenantName?: string;
  productTenantLogoUrl?: string;
  productTenantId?: string;
  isIFAOpportunity?: boolean;
  snapshotCreateLogoUrl?: string;
  snapshotCreateSalesName?: string;
  snapshotCreateSalesId?: string;
}

export enum PipelineSourceEnum {
  EXPRESSED_INTEREST_SUBSCRIPTION = 'EXPRESSED_INTEREST_SUBSCRIPTION', // 表达兴趣
  RESERVE_SUBSCRIPTION = 'RESERVE_SUBSCRIPTION', // 预约订单
  COMMON_SUBSCRIPTION = 'COMMON_SUBSCRIPTION', // 普通订单
  INVEST_SUBSCRIPTION = 'INVEST_SUBSCRIPTION', // 下单订单
}

export const PIPELINE_SOURCE_LABEL = {
  [PipelineSourceEnum.EXPRESSED_INTEREST_SUBSCRIPTION]: 'Express interest',
  [PipelineSourceEnum.RESERVE_SUBSCRIPTION]: 'Reserve',
  [PipelineSourceEnum.COMMON_SUBSCRIPTION]: 'Add manually',
  [PipelineSourceEnum.INVEST_SUBSCRIPTION]: 'Invest',
};
