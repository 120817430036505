<div class="no-data">
  <div *ngTemplateOutlet="default"></div>
  <ng-content select=".description"></ng-content>
</div>

<ng-template #default>
  <svg
    *ngIf="type === 'wave'"
    width="110"
    height="132"
    viewBox="0 0 110 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8C14 3.6 17.6 0 22 0H84C88.4 0 92 3.6 92 8V80C92 84.4 88.4 88 84 88H22C17.6 88 14 84.4 14 80V8Z"
      class="primary-fill"
    />
    <path
      d="M75 23H31C29.9 23 29 22.1 29 21C29 19.9 29.9 19 31 19H75C76.1 19 77 19.9 77 21C77 22.1 76.1 23 75 23Z"
      fill="#F5F6FA"
    />
    <path
      d="M75 38H31C29.9 38 29 37.1 29 36C29 34.9 29.9 34 31 34H75C76.1 34 77 34.9 77 36C77 37.1 76.1 38 75 38Z"
      fill="#F5F6FA"
    />
    <path
      d="M48 54H31C29.9 54 29 53.1 29 52C29 50.9 29.9 50 31 50H48C49.1 50 50 50.9 50 52C50 53.1 49.1 54 48 54Z"
      fill="#F5F6FA"
    />
    <path
      d="M48 68H31C29.9 68 29 67.1 29 66C29 64.9 29.9 64 31 64H48C49.1 64 50 64.9 50 66C50 67.1 49.1 68 48 68Z"
      fill="#F5F6FA"
    />
    <g opacity="0.8" filter="url(#filter0_b_9071_56258)">
      <path
        d="M64.0639 47H105.936C107.014 47 108.048 47.4299 108.81 48.1952C109.572 48.9605 110 49.9985 110 51.0807V76.9111C110 77.9934 109.572 79.0313 108.81 79.7966C108.048 80.5619 107.014 80.9918 105.936 80.9918H103.096C102.1 80.9918 101.145 81.3891 100.44 82.0963C99.7361 82.8035 99.3405 83.7626 99.3405 84.7627V86.2903C99.3413 86.4155 99.3089 86.5387 99.2466 86.6472C99.1843 86.7556 99.0944 86.8455 98.986 86.9075C98.8777 86.9695 98.7549 87.0014 98.6302 87C98.5055 86.9985 98.3835 86.9638 98.2766 86.8993L89.5426 81.558C88.953 81.1977 88.2753 81.0091 87.5851 81.0132H64.0745C63.5382 81.0146 63.0068 80.9094 62.5112 80.7036C62.0155 80.4978 61.5653 80.1955 61.1865 79.8142C60.8078 79.4329 60.5079 78.98 60.3043 78.4817C60.1006 77.9835 59.9972 77.4497 60.0001 76.9111V51.038C60.0113 49.9631 60.4444 48.9361 61.2053 48.1801C61.9662 47.424 62.9934 46.9999 64.0639 47Z"
        fill="#343434"
      />
    </g>
    <path
      d="M91 67.5002C88.8 67.5002 86.8 66.8002 85.6 65.7002C83.7 63.8002 81.7 63.0002 79.8 63.1002C77 63.2002 75 65.5002 75 65.5002C74.6 66.0002 73.8 66.1002 73.2 65.7002C72.6 65.3002 72.6 64.5002 73 64.0002C73.1 63.9002 75.7 60.8002 79.6 60.6002C82.2 60.4002 84.8 61.6002 87.3 63.9002C88.1 64.6002 89.9 65.2002 92 65.0002C93.1 64.9002 95.2 64.3002 96.4 62.4002C96.8 61.8002 97.5 61.6002 98.1 62.0002C98.7 62.4002 98.9 63.1002 98.5 63.7002C97.2 65.8002 95 67.1002 92.3 67.4002C91.9 67.4002 91.5 67.5002 91 67.5002Z"
      fill="white"
    />
    <g filter="url(#filter1_f_9071_56258)">
      <ellipse cx="54" cy="112" rx="40" ry="6" class="primary-fill" />
    </g>
    <defs>
      <filter
        id="filter0_b_9071_56258"
        x="-44"
        y="-57"
        width="258"
        height="248"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="52" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_9071_56258"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_9071_56258"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_9071_56258"
        x="0"
        y="92"
        width="108"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="7"
          result="effect1_foregroundBlur_9071_56258"
        />
      </filter>
    </defs>
  </svg>
  <svg
    *ngIf="type === 'magnifier'"
    width="112"
    height="132"
    viewBox="0 0 112 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_9071_56147)">
      <ellipse cx="54" cy="112" rx="40" ry="6" class="primary-fill" />
    </g>
    <path
      d="M16 8C16 3.58172 19.5817 0 24 0H86C90.4183 0 94 3.58172 94 8V80C94 84.4183 90.4183 88 86 88H24C19.5817 88 16 84.4183 16 80V8Z"
      class="primary-fill"
    />
    <path
      opacity="0.8"
      d="M66.0639 47H107.936C109.014 47 110.048 47.4299 110.81 48.1952C111.572 48.9605 112 49.9985 112 51.0807V76.9111C112 77.9934 111.572 79.0313 110.81 79.7966C110.048 80.5619 109.014 80.9918 107.936 80.9918H105.096C104.1 80.9918 103.145 81.3891 102.44 82.0963C101.736 82.8035 101.34 83.7626 101.34 84.7627V86.2903C101.341 86.4155 101.309 86.5387 101.247 86.6472C101.184 86.7556 101.094 86.8455 100.986 86.9075C100.878 86.9695 100.755 87.0014 100.63 87C100.506 86.9985 100.383 86.9638 100.277 86.8993L91.5426 81.558C90.953 81.1977 90.2753 81.0091 89.5851 81.0132H66.0745C65.5382 81.0146 65.0068 80.9094 64.5112 80.7036C64.0155 80.4978 63.5653 80.1955 63.1865 79.8142C62.8078 79.4329 62.5079 78.98 62.3043 78.4817C62.1006 77.9835 61.9972 77.4497 62.0001 76.9111V51.038C62.0113 49.9631 62.4444 48.9361 63.2053 48.1801C63.9662 47.424 64.9934 46.9999 66.0639 47Z"
      class="primary-fill-8"
    />
    <circle
      cx="86.0358"
      cy="64.4997"
      r="8"
      transform="rotate(30 86.0358 64.4997)"
      stroke="white"
      stroke-width="3"
    />
    <line
      x1="95.196"
      y1="69.366"
      x2="100.392"
      y2="72.366"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="21"
      x2="77"
      y2="21"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="36"
      x2="77"
      y2="36"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="52"
      x2="50"
      y2="52"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="66"
      x2="50"
      y2="66"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <defs>
      <filter
        id="filter0_f_9071_56147"
        x="0"
        y="92"
        width="108"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="7"
          result="effect1_foregroundBlur_9071_56147"
        />
      </filter>
    </defs>
  </svg>
  <svg
    *ngIf="type === 'exclamatory'"
    width="112"
    height="132"
    viewBox="0 0 112 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_5405_65584)">
      <ellipse cx="54" cy="112" rx="40" ry="6" class="primary-fill" />
    </g>
    <path
      d="M16 8C16 3.58172 19.5817 0 24 0H86C90.4183 0 94 3.58172 94 8V80C94 84.4183 90.4183 88 86 88H24C19.5817 88 16 84.4183 16 80V8Z"
      class="primary-fill"
    />
    <path
      opacity="0.8"
      d="M66.0639 47H107.936C109.014 47 110.048 47.4299 110.81 48.1952C111.572 48.9605 112 49.9985 112 51.0807V76.9111C112 77.9934 111.572 79.0313 110.81 79.7966C110.048 80.5619 109.014 80.9918 107.936 80.9918H105.096C104.1 80.9918 103.145 81.3891 102.44 82.0963C101.736 82.8035 101.34 83.7626 101.34 84.7627V86.2903C101.341 86.4155 101.309 86.5387 101.247 86.6472C101.184 86.7556 101.094 86.8455 100.986 86.9075C100.878 86.9695 100.755 87.0014 100.63 87C100.506 86.9985 100.383 86.9638 100.277 86.8993L91.5426 81.558C90.953 81.1977 90.2753 81.0091 89.5851 81.0132H66.0745C65.5382 81.0146 65.0068 80.9094 64.5112 80.7036C64.0155 80.4978 63.5653 80.1955 63.1865 79.8142C62.8078 79.4329 62.5079 78.98 62.3043 78.4817C62.1006 77.9835 61.9972 77.4497 62.0001 76.9111V51.038C62.0113 49.9631 62.4444 48.9361 63.2053 48.1801C63.9662 47.424 64.9934 46.9999 66.0639 47Z"
      fill="#FF8717"
    />
    <path
      d="M88.7101 68.2902L89.079 55.8136"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M88.8195 73.7782L88.8311 74.1689"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="21"
      x2="77"
      y2="21"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="36"
      x2="77"
      y2="36"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="52"
      x2="50"
      y2="52"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="66"
      x2="50"
      y2="66"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <defs>
      <filter
        id="filter0_f_5405_65584"
        x="0"
        y="92"
        width="108"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="7"
          result="effect1_foregroundBlur_5405_65584"
        />
      </filter>
    </defs>
  </svg>
  <svg
    *ngIf="type === 'country'"
    width="112"
    height="133"
    viewBox="0 0 112 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_17035_33741)">
      <ellipse cx="54" cy="112.167" rx="40" ry="6.00898" class="primary-fill" />
    </g>
    <path
      d="M16 8C16 3.58172 19.5817 0 24 0H86C90.4183 0 94 3.58172 94 8V80.1316C94 84.5499 90.4183 88.1316 86 88.1316H24C19.5817 88.1316 16 84.5499 16 80.1316V8Z"
      class="primary-fill"
    />
    <line
      x1="33"
      y1="21.0342"
      x2="77"
      y2="21.0342"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="36.0566"
      x2="77"
      y2="36.0566"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="52.0808"
      x2="50"
      y2="52.0808"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="66.1018"
      x2="50"
      y2="66.1018"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <g opacity="0.8" filter="url(#filter1_b_17035_33741)">
      <path
        d="M108 65.6818C108 77.6564 86.5 94 86.5 94C86.5 94 65 77.6564 65 65.6818C65 53.7073 74.6259 44 86.5 44C98.3741 44 108 53.7073 108 65.6818Z"
        fill="#E9A100"
      />
    </g>
    <path
      d="M81 70.3352L92.6703 59.3352"
      stroke="#F5F6FA"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M81.335 59L92.335 70.6703"
      stroke="#F5F6FA"
      stroke-width="4"
      stroke-linecap="round"
    />
    <defs>
      <filter
        id="filter0_f_17035_33741"
        x="0"
        y="92.1584"
        width="108"
        height="40.0181"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="7"
          result="effect1_foregroundBlur_17035_33741"
        />
      </filter>
      <filter
        id="filter1_b_17035_33741"
        x="-39"
        y="-60"
        width="251"
        height="258"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="52" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_17035_33741"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_17035_33741"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
  <svg
    *ngIf="type === 'success'"
    width="112"
    height="133"
    viewBox="0 0 112 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_20357_46693)">
      <ellipse cx="54" cy="112.167" rx="40" ry="6.00897" fill="#E9ECEF" />
    </g>
    <path
      d="M16 8C16 3.58172 19.5817 0 24 0H86C90.4183 0 94 3.58172 94 8V80.1316C94 84.5499 90.4183 88.1316 86 88.1316H24C19.5817 88.1316 16 84.5499 16 80.1316V8Z"
      fill="#CED4DA"
    />
    <path
      opacity="0.8"
      d="M66.0638 48.5703H66.0639H107.936C108.613 48.5703 109.265 48.8408 109.746 49.325C110.228 49.8095 110.5 50.4684 110.5 51.1571V77.0261C110.5 77.7149 110.228 78.3738 109.746 78.8583C109.265 79.3425 108.613 79.613 107.936 79.613H105.096C103.699 79.613 102.362 80.1709 101.377 81.1614C100.392 82.1516 99.8405 83.4928 99.8405 84.8895V85.0031L92.326 80.4008L92.3255 80.4005C91.4995 79.895 90.5494 79.6295 89.5812 79.6343L66.0745 79.6343L66.0706 79.6343C65.7334 79.6352 65.3991 79.569 65.087 79.4393C64.7749 79.3095 64.4909 79.1186 64.2516 78.8773C64.0122 78.636 63.8222 78.3489 63.6931 78.0324C63.5639 77.7159 63.4983 77.3765 63.5 77.0339H63.5001V77.0261V51.123C63.509 50.4415 63.784 49.7924 64.2633 49.3154C64.744 48.8371 65.3912 48.5703 66.0638 48.5703Z"
      fill="#44BB5C"
      stroke="#5DB73E"
      stroke-width="3"
    />
    <line
      x1="33"
      y1="21.0342"
      x2="77"
      y2="21.0342"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="36.0566"
      x2="77"
      y2="36.0566"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M97 60.0898L86.8334 69.6868C86.0111 70.463 84.7095 70.4038 83.9611 69.5561L79 63.9363"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="52.0811"
      x2="50"
      y2="52.0811"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="33"
      y1="66.1016"
      x2="50"
      y2="66.1016"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
    />
    <defs>
      <filter
        id="filter0_f_20357_46693"
        x="0"
        y="92.1582"
        width="108"
        height="40.0176"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="7"
          result="effect1_foregroundBlur_20357_46693"
        />
      </filter>
    </defs>
  </svg>
  <svg
    *ngIf="type === 'template'"
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M175.444 63.583V137.767C175.444 140.215 174.46 141.199 172.012 141.199H82.3621C81.78 141.199 81.2035 141.085 80.6657 140.862C80.1279 140.639 79.6392 140.313 79.2275 139.901C78.8159 139.49 78.4893 139.001 78.2665 138.463C78.0437 137.925 77.9291 137.349 77.9291 136.767V26.432C77.9291 23.984 78.9131 23 81.3611 23H134.861C135.443 23.0002 136.02 23.1151 136.557 23.3382C137.095 23.5613 137.584 23.8881 137.995 24.3L174.146 60.451C174.977 61.2817 175.444 62.4082 175.444 63.583Z"
      fill="url(#paint0_linear_21742_13495)"
    />
    <path
      d="M136.696 58.3146V24.1216C136.696 24.0298 136.723 23.9401 136.775 23.8638C136.826 23.7874 136.898 23.7279 136.983 23.6928C137.068 23.6576 137.161 23.6482 137.251 23.666C137.341 23.6838 137.424 23.7279 137.489 23.7927L174.583 60.8867C174.655 60.9564 174.705 61.0462 174.726 61.1445C174.746 61.2429 174.737 61.3452 174.698 61.438C174.66 61.5308 174.594 61.6099 174.51 61.6649C174.426 61.72 174.327 61.7485 174.227 61.7466H140.127C137.68 61.7466 136.696 60.7676 136.696 58.3146Z"
      fill="url(#paint1_linear_21742_13495)"
    />
    <path
      d="M159.075 86.5332H95.9141C94.8095 86.5332 93.9141 87.4286 93.9141 88.5332V93.3982C93.9141 94.5028 94.8095 95.3982 95.9141 95.3982H159.075C160.18 95.3982 161.075 94.5028 161.075 93.3982V88.5332C161.075 87.4286 160.18 86.5332 159.075 86.5332Z"
      fill="white"
    />
    <path
      d="M159.075 113.127H95.9141C94.8095 113.127 93.9141 114.022 93.9141 115.127V119.992C93.9141 121.097 94.8095 121.992 95.9141 121.992H159.075C160.18 121.992 161.075 121.097 161.075 119.992V115.127C161.075 114.022 160.18 113.127 159.075 113.127Z"
      fill="white"
    />
    <path
      d="M75.853 72.0207L67.446 59.9707C66.608 58.7707 66.236 58.0547 64.771 58.0547H27.48C25.006 58.0547 24 59.0607 24 61.5347V151.721C24 154.195 25.006 155.201 27.48 155.201H141.68C144.154 155.201 145.16 154.195 145.16 151.721V77.4207C145.16 74.9467 144.154 73.9407 141.68 73.9407H78.527C77.062 73.9367 76.69 73.2207 75.853 72.0207Z"
      fill="white"
    />
    <path
      d="M108.415 115.347V110.707C100.855 116.589 90.2671 116.589 82.7071 110.707C75.1472 104.826 64.5596 104.826 56.9996 110.707V115.347C64.5596 109.466 75.1472 109.466 82.7071 115.347C90.2671 121.229 100.855 121.229 108.415 115.347Z"
      fill="#2E4B9C"
    />
    <g filter="url(#filter0_f_21742_13495)">
      <path
        d="M101.222 177.1C125.523 177.1 145.222 176.204 145.222 175.1C145.222 173.995 125.523 173.1 101.222 173.1C76.9215 173.1 57.222 173.995 57.222 175.1C57.222 176.204 76.9215 177.1 101.222 177.1Z"
        fill="#9EB1BA"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_21742_13495"
        x="42.222"
        y="158.1"
        width="118"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="7.5"
          result="effect1_foregroundBlur_21742_13495"
        />
      </filter>
      <linearGradient
        id="paint0_linear_21742_13495"
        x1="126.687"
        y1="23"
        x2="126.687"
        y2="141.199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2E4B9C" />
        <stop offset="1" stop-color="#F1F2F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_21742_13495"
        x1="155.716"
        y1="23.6572"
        x2="155.716"
        y2="61.7467"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F1F2F7" stop-opacity="0.74" />
        <stop offset="1" stop-color="#F1F2F7" />
      </linearGradient>
    </defs>
  </svg>
</ng-template>
